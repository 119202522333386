import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import TransactionsTable from "../../components/Transactions/TransactionsTable/index";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    PersianDatePicker: VuePersianDatetimePicker,
    TransactionsTable,
  },
  data() {
    return {
      // datePickerStyles: {
      //     "primary-color":  "#f1ac1c",
      //     "secondary-color":  "#f1ac1c",
      //     "in-range-background":  "rgba(255,204,0,0.2)",
      //     "icon-background":  "#e9ecef",
      //     "text-color":  "#fff",
      //     "hover-color":  "#fff",
      //     "border-color":  "#27313e",
      //     "main-box-shadow": "1px 1px 8px 1px rgba(34, 34, 34, 0.8)",
      //     background:  "#192330",
      // },
      value: false,
      drawerStyles: {
        height: "100vh",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
        backgroundColor: "#141e2c",
      },
      drawerWidth: window.innerWidth >= 769 ? window.innerWidth >= 1000 ? "25%" : "30%" : "100%",
      loading: false,
      ordKeyword: "",
      rangeDate: "",
      recordValue: "",
      recordType: [],
      coinsList: [],
      coinScales: {},
      coinType: "",
      pageSize: 10,
      page: 1,
      total: 0,
      tableRecord: [],
    };
  },
  created() {
    this.rangeDate = new Date().getTime();
    this.setNavigation("nav-transactions");
    this.getCoinScales();
    this.getList(this.page);
    this.getAddrList();
    this.getType();
  },
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
      getCoinsList: "userCenter/GET_COINS_LIST_SERVICE",
      getTransactionTypes: "userCenter/GET_TRANSACTION_TYPES_SERVICE",
      getAllTransactions: "userCenter/GET_ALL_TRANSACTIONS_SERVICE",
      getFrontCoinScales: "market/GET_FRONT_COIN_SCALES_SERVICE",
    }),
    clearSelect(e, key) {
      e.stopPropagation();
      this[key] = ""
    },
    handleClear() {
      this.rangeDate = new Date().getTime();
      this.coinType = "";
      this.recordValue = "";
      this.getList(this.page);
      this.value = false;
    },
    changePage(pageindex) {
      this.page = pageindex;
      this.getList(this.page);
    },
    queryOrder() {
      try {
        this.page = 1;
        this.getList(this.page);
        this.value = false;
      } catch (ex) {
        this.$toast.error(this.$t("transactions.selectRangeTime"));
        return;
      }
    },
    getAddrList() {
      //get address
      this.getCoinsList().then((response) => {
        if (response.data.code === 0 && response.data.data.length > 0) {
          this.coinsList = response.data.data;
        }
      });
    },
    getType() {
      this.getTransactionTypes().then((response) => {
        if (response.data.code === 0 && response.data.data.length > 0) {
          this.recordType = response.data.data;
        }
      });
    },
    getCoinScales() {
      this.getFrontCoinScales().then((response) => {
        this.coinScales = response.data.data;
      });
    },
    getList(pageNo) {
      //get table withdraw
      let memberId = 0;
      // !this.$store.getters.isLogin && this.$router.push("/login");
      this.getLoginStatus && (memberId = this.getMember.id);
      let startTime = this.rangeDate[0] || "";
      let endTime = this.rangeDate[1] || "";
      let symbol = "";
      let type = "";
      this.coinType && (symbol = this.coinType);
      if (this.recordValue == 0 || this.recordValue) {
        type = this.recordValue;
      }
      // this.recordValue!="" || this.recordValue!=undefined && (type = this.recordValue);
      this.loading = true;
      let payload = {
        pageNo: pageNo,
        pageSize: this.pageSize,
        startTime,
        endTime,
        memberId,
        symbol,
        type,
      };
      this.getAllTransactions(payload)
        .then((response) => {
          let transactionsData = response.data.data;
          if (response.data.code === 0) {
            this.loading = false;
            if (transactionsData) {
              this.total = transactionsData.totalElements;
              this.tableRecord = transactionsData.content;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      getLoginStatus: "global/IS_LOGIN",
      getMember: "global/MEMBER",
      getStatusCode: "global/GET_STATUS_CODE",
    }),
    isLogin() {
      return this.getLoginStatus;
    },
  },
  mounted() {},
};
